<template>
  <gf-base>
    <gf-card title="Purchase Orders" subtitle="List of Purchase Orders">
      <template #toolbar>
        <gf-button type="primary" @click="newPurchaseOrder">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" cx="9" cy="15" r="6"/>
                  <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          New Record
        </gf-button>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="1" label="Draft"></el-option>
          <el-option :value="2" label="For Approval"></el-option>
          <el-option :value="3" label="Cancelled"></el-option>
          <el-option :value="4" label="Rejected"></el-option>
          <el-option :value="5" label="Active"></el-option>
          <el-option :value="6" label="Receiving"></el-option>
          <el-option :value="7" label="Received"></el-option>
          <el-option :value="8" label="Void"></el-option>
        </el-select>
      </template>
      <gf-table :data="purchaseOrders" :column-filter="columnFilter" @row-click="readPurchaseOrder" v-loading="loading">
        <el-table-column v-if="columnFilter[0].show" label="Order #" min-width="150" prop="po_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Ship To" min-width="200" prop="ship_to.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Status" min-width="150" prop="status">
          <template slot-scope="scope">
            <gf-label inline pill light type="dark" v-if="scope.row.status === 1">Draft</gf-label>
            <el-dropdown v-else-if="scope.row.status === 2" trigger="click" @command="statusCommand($event, scope.$index)">
              <gf-label inline pill light type="primary">For Approval <i class="el-icon-arrow-down el-icon--right"></i></gf-label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="approve" v-if="isApprover"><i class="el-icon-check" style="color: #67C23A;"></i> Approve</el-dropdown-item>
                <el-dropdown-item command="reject" v-if="isApprover"><i class="el-icon-close" style="color: #F56C6C;"></i> Reject</el-dropdown-item>
                <el-dropdown-item command="cancel"><i class="el-icon-remove" style="color: #F56C6C;"></i> Cancel</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <gf-label inline pill light type="danger" v-if="scope.row.status === 3">Cancelled</gf-label>
            <gf-label inline pill type="danger" v-if="scope.row.status === 4">Rejected</gf-label>
            <gf-label inline pill type="primary" v-if="scope.row.status === 5">Active</gf-label>
            <gf-label inline pill light type="info" v-if="scope.row.status === 6">Receiving</gf-label>
            <gf-label inline pill type="info" v-if="scope.row.status === 7">Received</gf-label>
            <gf-label inline pill light type="warning" v-if="scope.row.status === 8">Void</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Quantity" min-width="150" prop="unit_counts">
          <template #default="slot">
            {{ $NumberFormat(slot.row.unit_counts) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Currency" min-width="150" prop="currency.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Company Name" min-width="250" prop="supplier.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Total Cost" min-width="150" prop="total">
          <template #default="slot">
            {{ $NumberFormat(slot.row.total) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[7].show" label="Note" min-width="150" prop="message">
        </el-table-column>
        <el-table-column v-if="columnFilter[8].show" label="Reference" min-width="150" prop="reference">
        </el-table-column>
        <el-table-column v-if="columnFilter[9].show" label="Issued Date" min-width="150" prop="issue_date">
          <template #default="slot">
            {{ $DateFormat(slot.row.issue_date) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[10].show" label="Created" min-width="150" prop="created_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[11].show" label="Due" min-width="150" prop="stock_due">
          <template #default="slot">
            {{ $DateFormat(slot.row.stock_due) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[12].show" label="Last Updated" min-width="150" prop="updated_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getPurchaseOrders"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import PurchaseOrderService from '@/services/v1/PurchaseOrder'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      // Tab Fields
      columnFilter: [
        { label: 'Order #', show: true },
        { label: 'Ship To', show: false },
        { label: 'Status', show: true },
        { label: 'Quantity', show: true },
        { label: 'Currency', show: false },
        { label: 'Company Name', show: true },
        { label: 'Total Cost', show: true },
        { label: 'Note', show: false },
        { label: 'Reference #', show: false },
        { label: 'Issued Date', show: false },
        { label: 'Created', show: false },
        { label: 'Due', show: true },
        { label: 'Last Updated', show: true }
      ],

      search: '',
      filter: {},
      status: null,
      isApprover: false,

      purchaseOrders: [],

      loading: false,

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1
      this.getPurchaseOrders()
    }, 400)
  },
  methods: {
    // main
    async statusCommand (command, index) {
      try {
        const poService = new PurchaseOrderService(this.purchaseOrders[index].id)

        if (command === 'approve') {
          await poService.approve()
          this.$message.success(`${this.purchaseOrders[index].po_number} successfully approved`)
          return
        }

        if (command === 'reject') {
          await poService.reject()
          this.$message.success(`${this.purchaseOrders[index].po_number} has been rejected`)
          return
        }

        if (command === 'cancel') {
          await poService.cancel()
          this.$message.success(`${this.purchaseOrders[index].po_number} has been cancelled`)
          return
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.getPurchaseOrders()
      }
    },

    // nav
    newPurchaseOrder () {
      this.$router.push({ name: 'purchase-order-new' })
    },
    readPurchaseOrder (row) {
      this.$router.push({ name: 'purchase-order-detail', params: { id: row.id } })
    },

    // filter
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['po.status'] = { '=': this.status }
        this.getPurchaseOrders()
        return
      }

      delete this.filter['po.status']
      this.getPurchaseOrders()
    },

    // service fetch
    async getPurchaseOrders () {
      try {
        this.loading = true
        const poService = new PurchaseOrderService()
        const result = await poService.list(this.pagination.page, null, this.filter, null)
        this.purchaseOrders = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.isApprover = this.$GetPermission('PO_APPROVAL')

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock Control', route: '' },
      { title: 'Purchase Order' }
    ])

    this.getPurchaseOrders()
  }
}
</script>
